* {
  font-family: "Guardian-EgypTT",Charter,"Charter Bitstream",Cambria,"Noto Serif Light","Droid Serif",Georgia,serif;
  /* font-family: GraphikRegular, Arial, Helvetica, sans-serif; */
}

* {
  text-decoration: none;
}

.myreviews-review-container {
  display: grid;
  grid-template-columns: minmax(0,40%) minmax(0, 60%);
  grid-template-rows: auto;
  height: auto;
  margin-bottom: 60px;
}

.myreviews-image-container {
  min-width: 210px;
  height: 170px;
  margin-bottom: 1rem;
}

.myreviews-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.my-reviews-main {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-reviews-upper {
  border-top: 1px solid rgb(241, 101, 34, 0.2);
  background-color: rgb(241, 101, 34, 0.2);
  position: absolute;
  left: 0;
  top: 7.5rem;
  width: 100%;
  min-height: 100px;
  min-width: 100%;
  z-index: -5
}

.my-reviews-header {
  margin: 2rem auto 0.5rem;
  font-size: 1.5rem;
  width: 1220px;
}

.my-reviews-name {
  font-size: 1rem;
  margin: 0 auto 2rem;
  width: 1220px;
  display: flex;
  align-items: center;
}


.my-reviews-outer {
  width: 1226px;
  padding-top: 8.2rem;
}

.my-reviews-inner {
  display: grid;
  grid-template-columns: minmax(0,50%) minmax(0,50%);
  margin-top: 1rem;
}


.my-single-info {
  padding: 0 25px;
  position: relative;
}

.my-single-product-name {
  color: rgb(241, 101, 34, 0.8);
  font-size: 18px;
}

.my-single-review-date {
  color: rgba(180, 173, 173, 0.8);
  font-size: 15px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.my-single-review {
  margin-top: 5px;
  overflow-y: auto;
  height: 100px;
}

.review-button-wrap {
  display: flex;
  justify-content:space-between;
  align-items: baseline;
  padding: 0px 2px 2px 0px;
  margin-top: 10px;
  margin-bottom: 15px;
  min-width: 0;
}


.review-button {
  cursor: pointer;
  width: 115px;
  padding: 3px 10px;
  /* margin: 3px 0px 20px 0px; */
  border: 1px rgb(241, 101, 34) solid;
  border-radius: 4px;
  /* background: rgb(241, 101, 34); */
  background: rgb(241, 101, 34);
  color: white;
}

.review-button:hover {
  background: white;
  /* border: 1px rgb(241, 101, 34) solid; */
  border: 1px rgb(241, 101, 34) solid;
  color:rgb(241, 101, 34);
  /* color:rgb(241, 101, 34); */
}
/*
.fa-quote-left {
  color: rgb(241, 101, 34);
  margin-right: 6px;
}

.fa-quote-right {
  color: rgb(241, 101, 34);
  margin-left: 6px;
} */

.fa-star {
  color: rgb(241, 101, 34);
}

.fa-star-half-o {
  color: rgb(241, 101, 34);
}

.single-review {
  /* font-weight: bolder; */
  margin-bottom: 30px;
}

.single-review-name {
  font-weight: bold;
  font-size: 20px;
}

.single-review-review {
  margin-top: 5px;
  height: 60px;
  overflow-y: auto;
}

/* -------edit review-------- */

.edit-review-form {
  z-index: 500;
}

.edit-review-form .form-input-wrapper {
  border: rgb(200, 200, 200) 1px solid;
  width: 95%;
  border-radius: 9px;
  /* margin: 15px 25px 25px 25px; */
  margin-top: 5px;
  padding-left: 10px;
}

.edit-review-form .review-field {
  color: rgb(241, 101, 34);
  /* font-size: 12px; */
  padding-left: 3px;
}

.review-modal-subheader {
  display: flex;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 20px;
  margin: 20px 20px 10px 20px;
}

.edit-review-form textarea {
  resize: none;
  width: 90%;
  height: 62px;
  font-size: 16px;
  padding-left: 4px;
  border: none;
  border-radius: 4px;
  margin: 2px 0px;
}

textarea:focus {
  outline: none;
}

/* .form-input-wrapper select {
  font-size: 14px;
  padding: 4px;
  border: none;
  border-radius: 4px;
  margin: 4px 0px;
} */

select:focus {
  outline: none;
}

.edit-review-form .form-input-break{
  background-color: rgb(200, 200, 200);
  height:1px;
  margin-left: -11px;
  width: 104%;
  margin-top: 4px;
  margin-bottom: 4px;
}

.edit-review-form .form-input-wrapper {
  border: rgb(200, 200, 200) 1px solid;
  width: 95%;
  border-radius: 9px;
  /* margin: 15px 25px 25px 25px; */
  margin-top: 5px;
  padding-left: 10px;
  /* padding-top: 5px; */
}

.validation-errors {
  list-style: none;
  font-size: 14px;
  color: rgb(241, 101, 34);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* align-items: flex-start;
  margin-left: 50px; */
}

.edit-review-form .modal-submit-button {
  width: 99%;
  padding: 10px;
  font-size: 14px;
  /* font-weight: bold; */
  margin-top: 10px;
  border: 1px rgb(241, 101, 34) solid;
  border-radius: 4px;
  background: rgb(241, 101, 34);
  color: white;
}

.edit-review-form .modal-submit-button:hover {
  background: white;
  border: 1px rgb(241, 101, 34) solid;
  color:rgb(241, 101, 34);
}

/* .create-review-wrapper {
  position: fixed;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */


/* ----product detail create review button-------- */
.create-new-review-button {
  cursor: pointer;
  width: 180px;
  height: 35px;
  padding: 3px 10px;
  margin: 3px 0px 20px 0px;
  border: 1px rgb(241, 101, 34) solid;
  border-radius: 4px;
  background: rgb(241, 101, 34);
  color: white;
}

.create-new-review-button:hover {
  background: white;
  border: 1px rgb(241, 101, 34) solid;
  color:rgb(241, 101, 34);
}

/* ---------Hover Stars--------- */
input[type="radio"]{
  display: none;
}

.star {
  cursor: pointer;
  transition: color 200ms;
  margin-right: 1px;

}

.prod-star {
  margin-bottom: -3px;
}

.stars-wrapper {
  margin-left: 5px;
  margin-top: -12px;
}

/* -------product details page reviews------- */

.product-review-button-wrap {
  display: flex;
  /* justify-content:space-around; */
  align-items: baseline;
  padding: 0px 2px 2px 0px;
  margin-top: 10px;
  margin-bottom: 15px;
  min-width: 0;
}


.product-review-button {
  cursor: pointer;
  width: 80px;
  padding: 3px 10px;
  /* margin: 3px 0px 20px 0px; */
  border: 1px rgb(241, 101, 34) solid;
  border-radius: 4px;
  background: rgb(241, 101, 34);
  color: white;
}

.product-review-button:hover {
  background: white;
  border: 1px rgb(241, 101, 34) solid;
  color:rgb(241, 101, 34);
}


/* -------create review modal------- */
.create-hover {
  margin-left: 80px;
}

.create-review-field {
  /* color: rgb(241, 101, 34); */
  color: black;
  padding-left: 3px;
}


.create-review-modal-whole textarea {
  resize: none;
  width: 100%;
  height: 120px;
  font-size: 16px;
  padding-left: 4px;
  border: none;
  border-radius: 4px;
  margin: 4px 0px;
}

.create-review-modal-whole .stars-wrapper {
  margin-top: -20px;
  margin-bottom: 5px;
  margin-left: -10px;
}
.create-review-modal-whole .review-field {
  /* color: rgb(241, 101, 34); */
  color: black;
  /* font-size: 12px; */
  padding-left: 3px;
}

.review-modal-subheader {
  display: flex;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 20px;
  margin: 20px 20px 10px 20px;
}

.create-review-modal-whole textarea {
  resize: none;
  width: 100%;
  height: 120px;
  font-size: 16px;
  padding-left: 4px;
  border: none;
  border-radius: 4px;
  margin: 2px 0px;
}

textarea:focus {
  outline: none;
}

.create-review-modal-whole .form-input-break{
  background-color: rgb(200, 200, 200);
  height:1px;
  margin-left: -11px;
  width: 104%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.create-review-modal-whole .form-input-wrapper {
  border: rgb(200, 200, 200) 1px solid;
  width: 100%;
  border-radius: 9px;
  /* margin: 15px 25px 25px 25px; */
  margin-top: 5px;
  margin-bottom: 0px;
  padding-left: 10px;
  /* padding-top: 5px; */
}

.create-modal-button-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.create-review-modal-whole .modal-submit-button {
  width: 85%;
  padding: 10px;
  font-size: 16px;
  /* margin: 0px 25px 5px 25px; */
  margin-top: 15px;
  margin-bottom: 10px;
  border: 1px rgb(241, 101, 34) solid;
  border-radius: 4px;
  background: rgb(241, 101, 34);
  color: white;
}

.create-review-modal-whole .modal-submit-button:hover {
  background: white;
  border: 1px rgb(241, 101, 34) solid;
  color:rgb(241, 101, 34);
}

.create-review-modal-whole  {
  width: 500px;
  padding: 10px;
}

.create-form-input-wrapper {
  padding-left: 45px;
  padding-right: 45px;
  margin-top: 15px;
}
