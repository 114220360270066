/* TODO Add site wide styles */
a,
a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}


