* {
    font-family: "Guardian-EgypTT",Charter,"Charter Bitstream",Cambria,"Noto Serif Light","Droid Serif",Georgia,serif;
  }

.edit-product-errors {
    color: red;
    margin-top: 5px;
}

.img-product-errors {
    color: red;
    margin-top: 5px;
    margin-left: -4px;
}

.createproduct-total {
    display: flex;
    align-items: center;
    justify-content: center;
}

.createproduct-wrapper {
    padding: 25px;
    display: flex;
    display: flex;
    flex-direction: column;
}
.createproduct-title {
    font-size: 18px;
    font-weight: 300;
    margin-right: 6px;

}

/* .editproduct-sub--title {
    font-size: 10px;
    font-weight: 200;
    margin-left: 10px;
    background-color: aqua;
} */

.createproduct-form-title {
    color: rgba(241, 100, 34, 0.878);
    font-size: 50px;
    font-weight: 500;
    /* display: flex;
    align-items: center; */
    margin: auto;
}

.createproduct-form {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.createproduct-input {
    border-radius: 5px;
    border: 1px lightgray solid;
    width: 600px;
    height: 30px;
    margin-bottom: 10px;
    margin-top: 8px;
    color:grey;
    font-size: 18px;
}
.createproduct-input-description {
    border-radius: 5px;
    border: 1px lightgray solid;
    width: 600px;
    height: 70px;
    margin-bottom: 10px;
    margin-top: 8px;
    color:grey;
    font-size: 18px;
}
.createproduct-input-select {
    border-radius: 5px;
    border: 1px lightgray solid;
    width: 605px;
    height: 30px;
    margin-bottom: 10px;
    margin-top: 8px;
    color:grey;
    font-size: 18px;
}
.createproduct-button {
    cursor: pointer;
    width: 200px;
    height:40px;
    border: 1px rgb(241, 101, 34) solid;
    border-radius: 4px;
    background:  rgba(241, 100, 34, 0.878);
    color: white;
    font: bold;
    font-size: 20px;
    font-size: 300;
}

.createproduct-button:hover {
    background: white;
    border: 1px  rgba(241, 100, 34, 0.878) solid;
    color: rgba(241, 100, 34, 0.878);
  }

  .addImage-input {
    border-radius: 5px;
    border: 1px lightgray solid;
    width: 600px;
    height: 30px;
    margin-bottom: 10px;
    margin-top: 8px;
    color:grey;
    font-size: 18px;
}

.imgproduct-button {
    cursor: pointer;
    width: 120px;
    height:30px;
    border: 1px rgb(241, 101, 34) solid;
    border-radius: 4px;
    background:  rgba(241, 100, 34, 0.878);
    color: white;
    font: bold;
    font-size: 20px;
    font-size: 300;
}

.createproduct-button:hover {
    background: white;
    border: 1px  rgba(241, 100, 34, 0.878) solid;
    color: rgba(241, 100, 34, 0.878);
  }
