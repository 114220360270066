/* .search-header-container {
  font-family: "Graphik Webfont",-apple-system,"Helvetica Neue","Droid Sans",Arial,sans-serif;
  background-color: #F8EBE5;
  border-top: 1px solid rgb(243, 220, 211);
  position: absolute;
  left: 0;
  top: 120px;
  width: 100%;
  height: 100px;
  min-height: 100px;
  min-width: 100%;
  z-index: -3;
} */

/* .search-header {
  font-family: "Graphik Webfont",-apple-system,"Helvetica Neue","Droid Sans",Arial,sans-serif;
  font-size: 1.5rem;
  width: 100%;
  margin: 3rem 5rem 5rem 5rem;
} */

.search-sum {
  font-family: GraphikRegular, Arial, Helvetica, sans-serif;
  max-width: 97%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  display: inline-block;
}

.search-main {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 0 auto 1.5rem;
  width: 1220px;
  min-height: 850px;
}

.search-caption {
  font-family: "Graphik Webfont",-apple-system,"Helvetica Neue","Droid Sans",Arial,sans-serif;
  font-size: 1.5rem;
  width: 100%;
  margin: 1rem 0;
}

.search-products-main {
  display: grid;
  grid-template-columns: repeat(4, 308px);
  justify-content: center;
}

.search-product-body {
  margin: 0 0.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.search-product-img-container {
  width: 100%;
  height: 250px;
}

.search-product-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.search-product-name {
  font-family: GraphikLight, Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  margin: 0.3rem 0 0.2rem;
  color:black;
}

.search-product-stars {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.2rem;
}

.star-display {
  padding-right: 2px;
  width: 16px;
  height: 16px;
}

.search-product-num-reviews {
  font-family: GraphikLight, Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #616161;
}

.fa-sharp.fa-solid.fa-star {
  color: black;
}

.search-product-price {
  font-family: GraphikRegular, Arial, Helvetica, sans-serif;
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
  color: black;
}

.search-product-shop {
  font-family: GraphikLight, Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #616161;
  margin-bottom: 0.8rem;
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
  .search-header {
    width: 1199px;
  }

  .search-main {
    width: 1200px;
  }

  .search-products-main {
    grid-template-columns: repeat(4, 303px);
  }
}

@media screen and (max-width: 1280px) {
  .search-header {
    width: 1080px;
  }

  .search-main {
    width: 1081px;
  }

  .search-products-main {
    grid-template-columns: repeat(4, 273px);
  }
}
