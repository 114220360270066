
.review-field {
  color: rgb(241, 101, 34);
  /* font-size: 12px; */
  padding-left: 3px;
}

.review-modal-subheader {
  display: flex;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 20px;
  margin: 20px 20px 10px 20px;
}

textarea {
  resize: none;
  width: 90%;
  font-size: 14px;
  padding: 4px;
  border: none;
  border-radius: 4px;
  margin: 4px 0px;
}

textarea:focus {
  outline: none;
}

select {
  /* color: rgb(255, 90, 96); */
  /* width: 12%; */
  font-size: 14px;
  padding: 4px;
  border: none;
  border-radius: 4px;
  margin: 4px 0px;
}

select:focus {
  outline: none;
}

/* option {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
} */

.create-review-button {
  cursor: pointer;
  width: 180px;
  height: 35px;
  padding: 3px 10px;
  margin: 3px 0px 20px 0px;
  border: 1px rgb(241, 101, 34) solid;
  border-radius: 4px;
  background: rgb(241, 101, 34);
  color: white;
  font: bold;
  font-size: 15px;
}

.create-review-button:hover {
  background: white;
  border: 1px rgb(241, 101, 34) solid;
  color:rgb(241, 101, 34);
}
