.cart-mostout-div{
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: top;

}


.cart-leftpart{
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: flex-start

}


.items-count-in-cart{
    font-size: 30px;
    margin-bottom: 120px;
    font-weight: 500

}



.cart-empty-message{
    text-decoration: none;
    margin-top: 50px;


}
/* .cart-is-empty{
    text-decoration-line: underline;
    text-decoration-color: lightgreen;
    text-decoration-thickness: 20px;

} */
.cart-need-login{
    font-size: 35px;
    text-align: center;
    margin-top: 150px;
    text-decoration-line: underline;
    text-decoration-color: lightgreen;
    text-decoration-thickness: 20px;

}

/* #########cart  items  cards########### */

.cart-item-container-div{
    display: flex;
    margin-top: -30px;
    margin-bottom: 50px;
    justify-content: space-between;
    border-bottom: 1px solid rgb(217, 217, 217);
    width: 100%;
/* padding-top: 40px */
}


.cart-image-container{
    width: 212.337px;
    height: 220.613px;
    margin-bottom: 35px;
}

.cart-image-container > img {
    object-fit: cover;
    border-radius: .7em;
    border: 1px solid rgb(228, 228, 228);
    width: 212.337px;
    height: 168.613px;
    max-width: 212.337px;
    max-height: 168.613px;
    margin-top: 40px;
    transition: .2s ease-in-out;

}
.cart-image-container > img:hover {
    cursor: pointer;
    opacity: .2;
}

.cart-item-name{
    width: 400px;
    height: 60px;
    position: relative;
    left:15px;
    top: 33px;
    font-size: 18px;
    cursor: pointer;
}
.cart-item-name:hover{
    text-decoration: underline;
}

.cart-product-name-remove{
    /* width: 30%;
    max-width: 30%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}


.cart-item-remove-item-button{
    width: fit-content;
    border-style: none;
    padding: .5em .8em;
    /* transition: transform 200ms cubic-bezier(0.345, 0.115, 0.135, 1.42), background 200ms ease-out,box-shadow 200ms ease-out; */
    margin-left: .2em;
    margin-top:40px;
    background-color: white;
    cursor: pointer;
    font-size: 16px;
    border-radius: 18px;
    font-weight: 570;

}



.cart-item-quantity-select{
    position: relative;
    width: fit-content;
    height: fit-content;
    top: 33px;
}



.cart-quantity-options{
    width: 5em;
    height: 3em;
    border: 1px solid rgb(195, 194, 194);
    box-shadow: 0 0 5px #bdbdbd;
    border-radius: .3em;
    padding-left: .5em;

}
.cart-quantity-options:hover {
    cursor: pointer;
    border: 1px solid rgb(126, 126, 126);
    box-shadow: 0 0 7px #bdbdbd;
}

.cart-item-remove-item-button:hover {
  background-color: #EFEFEF;
  cursor: pointer;
}


.quantity-price-box{
 width: 100px
}


.item-total-container{
 /* padding-top: 5px */
    position: relative;
    width: fit-content;
    height: fit-content;
    top: 20px;
}


/* ###############Cart Total Card############## */
.cart-checkout-card{
    display: flex;
    width: 30%;
    position: sticky;
    top: 20px;
    flex-direction: column;
    justify-content: space-around;
    height: 20em;
    box-shadow: 0 0 12px #c3c3c3;
    border-radius: .7em;
    margin-top: 10em;
    padding: 1em;
    background-color: white;
    z-index: 500;
}

.checkout-card{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(172, 170, 170);
    margin-bottom: 2em;
    padding-bottom: 1em;

}
.last-total{
    font-weight: 550;
}
/* .sales-tax,
.shipping-cost,
.items.total  {



} */
.cart-checkout-button{
    color: white;
    background-color: black;
    border: none;
    border-radius: 3em;
    font-size: large;
    height: 2.6em;
    width: 90%;
    justify-self: center;
    cursor: pointer;

}
.buy-item-button-container {
    display: flex;
    justify-content: center;
}

.items-total.checkout-card {
    border-bottom: none;
    padding-bottom: .2em;
}

.cart-checkout-button:hover{
    transform:scale(1.1,1.1);
    /* -webkit-transform:scale(1.3,1.3);
    -moz-transform:scale(1.3,1.3); */
}
.other-people-message{
    color: rgb(241, 101, 34);
    margin-top: 70px;
    margin-left: 15px;
    font-weight: 500;
    font-size: 17px

}
