.searchBar-main {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 5px;
  margin-right: 5px;
  width: 60%;
}

.navBar-searchBar-container {
  border: black 2.5px solid;
  border-radius: 30px;
  background-color: #F3F3F3;
  width: 98%;
  height: 43px;
  display: flex;
  align-items: center;
  margin: 4px 10px 0 5px;
}

.product-not-found-outer {
  border-top: 1px solid rgb(211, 211, 211);
  background-color: #FDEBD2;
  position: absolute;
  left: 0;
  top: 120px;
  width: 100%;
  height: 120px;
  min-width: 100%;
  z-index: -3;
}


.searchBar-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.searchBar-input {
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  font-family: GraphikLight, Arial, Helvetica, sans-serif;
  padding: 1.5px 20px;
  width: 100%;
  cursor: default;
}

.fa-sharp.fa-solid.fa-magnifying-glass {
  font-size: 1.3rem;
  color: black;
}

.searchBar-button {
  background: none;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}


.search-header,
.product-not-found-header {
  margin: 2rem auto 0;
  font-family: GuardianEgypLight, Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  width: 1220px;
  box-sizing: border-box;
}

.return-to-home-link {
  box-sizing: border-box;
  margin: 1rem auto 0;
  width: 1220px;
  font-family: GraphikLight, Arial, Helvetica, sans-serif;
}

.search-main {
  padding-top: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 14rem;
}

.category-product-img-outer {
  width: 100%;
  height: 250px;
}

.search-again-message {
  font-family: GraphikLight, Arial, Helvetica, sans-serif;
  font-size: 0.85rem;
  margin-top: 0.5rem;
}

.search-errors {
  width: 500px;
  color: red;
  position: absolute;
  top: 30px;
  left: 28px;
  font-family: GraphikRegular, Arial, Helvetica, sans-serif;
  font-size: 0.7rem;
}
